import {
  defaultLocale,
  locales,
} from '@bottlebooks/gatsby-theme-event/src/wrapElement/lingui/i18n.config';
import { i18n } from '@lingui/core';
export { defaultLocale, locales };

export async function activate(locale) {
  const catalogs = await Promise.all([
    import(
      /* webpackMode: "lazy", webpackChunkName: "i18n-[index]" */
      `@bottlebooks/gatsby-theme-event/src/locales/${locale}.js`
    ),
    import(
      /* webpackMode: "lazy", webpackChunkName: "i18n-[index]" */
      `../../../../locales/${locale}.js`
    ),
  ]);

  await Promise.all(catalogs.map((catalog) => i18n.load(locale, catalog)));
  return i18n.activate(locale);
}
