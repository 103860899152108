import React from 'react';
import Helmet from 'react-helmet';
export default function wrapRootElement({ element }) {
  return (
    <React.Fragment>
      <Helmet>
        <script src="/iframe-resizer/js/iframeResizer.contentWindow.min.js"></script>
      </Helmet>
      {element}
    </React.Fragment>
  );
}
