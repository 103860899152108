module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/@bottlebooks/gatsby-theme-event/gatsby-browser.js'),
      options: {"plugins":[],"rootPath":"/de","homePath":"http://www.weinburgenland.at/","exhibitorBase":"aussteller","producerBase":"winzer","productBase":"weine","eventId":"5e84612e32153674782d6a34","locale":"de","showCountryFilter":true,"showRegionFilter":true,"showCityFilter":true,"landingPageFilter":{"unserweinPremium":{"eq":"yes"},"mainImage":{"url":{"ne":null}},"logo":{"url":{"ne":null}}}},
    },{
      plugin: require('../node_modules/@bottlebooks/gatsby-theme-event/gatsby-browser.js'),
      options: {"plugins":[],"rootPath":"/en","homePath":"http://www.weinburgenland.at/","exhibitorBase":"exhibitors","producerBase":"producers","productBase":"wines","eventId":"5e84612e32153674782d6a34","locale":"en","showCountryFilter":true,"showRegionFilter":true,"showCityFilter":true,"landingPageFilter":{"unserweinPremium":{"eq":"yes"},"mainImage":{"url":{"ne":null}},"logo":{"url":{"ne":null}}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-44225398-8","head":false,"anonymize":true,"respectDNT":true,"exclude":["/preview/**"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
